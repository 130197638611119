import {Fragment, useState} from "react";
import EditJoinClubSettingsForm from "../Forms/EditJoinClubSettingsForm";


export default function EditJoinClubSettingsModal(props) {
  const [changeMembershipCycleModalIsOpen, setChangeMembershipCycleModalIsOpen] = useState(false);

  return (
    <Fragment>
      <div className={`modal__container narrow ${changeMembershipCycleModalIsOpen ? 'hidden' : ''}`}>
        <div className="modal__head">
          <div className="modal__icon"><i className="material-icons-outlined">edit</i></div>
          <h2 className="modal__title">Enable Join & Renew</h2>
          <button
            className="modal__close"
            onClick={() => {props.closeModal()}}
          >
            <i className="material-icons-outlined">clear</i>
          </button>
        </div>

        <div className="modal__body">
          <EditJoinClubSettingsForm
            {...props}
            changeMembershipCycleModalIsOpen={changeMembershipCycleModalIsOpen}
            setChangeMembershipCycleModalIsOpen={setChangeMembershipCycleModalIsOpen}
          />
        </div>
      </div>
    </Fragment>
  );
}