import React, { Component, Fragment } from "react";
import Table from "./Table";

export default class PushNotificationList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			tableNeedsRefresh: false,
		};

		this.setTableRefresh = this.setTableRefresh.bind(this);
	}

	setTableRefresh(value) {
		this.setState({ tableNeedsRefresh: value });
	}

	render() {
		let panelTitle = "Review Association Push Notifications";
		let panelMessage =
			"Push Notifications will appear on a golfer's device if they have enabled Push Notifications on their device and within the GHIN Mobile App. ";

		return (
			<Fragment>
				<div className="row">
					<div className="col">
						<div className="panel">
							<div className="panel__head">{panelTitle}</div>
							<div className="panel__body">
								<div className="row">
									<div className="col is-5-of-5">
										<p>
											<span className="bold">Note:</span>
											{" " + panelMessage}
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col">
										<Table
											user={this.props.user}
											setTableRefresh={(value) => this.setTableRefresh(value)}
											tableNeedsRefresh={this.state.tableNeedsRefresh}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}
