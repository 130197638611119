import React, {Component, Fragment} from 'react';
import 'react-picky/dist/picky.css'; // Include CSS
import debounce from "debounce-promise";
import GhinFilterSelect from "../../shared/GhinFilterSelect";
import ClubService from "../../../services/api/club";
import AssociationService from "../../../services/api/association";
import TableService from "../../../services/tableService";

const yesNoOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export default class TableFilter extends Component {
    constructor(props) {

        super(props);
		let status = [{label: 'Active', value: 'Active'}];
		if (this.props.checkBox === 'true') {
			status.push({label: 'Inactive', value: 'Inactive'})
		}
			this.state = {
            associations: [],
            clubs: [],
            filters: {
              search: this.props.searchText,
              is_test: { value: false, label: "No" },
              include_trial_golfers: { value: false, label: "No"},
				status: status
            }
        };

      if (this.props.association) {
        this.state.filters.association_id = this.props.association
        this.loadClubs(this.props.association.value)
      }

    }

    componentDidMount() {
      this.loadAssociations();
    }
    
    componentDidUpdate(prevProps, prevState) {
      if (prevState.filters.is_test !== this.state.filters.is_test) {
        this.loadAssociations();
      }
    }
    
    updateFilter = (name, value) => {

        let newState = {
            filters: {
                ...this.state.filters,
                [name]: value
            },
        };

        if (name === 'association_id') {
            newState['filters']['club'] = null;
            newState['clubs']  = [];
        }

        this.setState(newState, () => {
            this.updateTable();

            if (name === 'association_id') {
                this.loadClubs(value.value);
            }
        })
    };

    updateTable = debounce(() => {
        let filteredArray = TableService.filtersToArray(this.state.filters);
        this.props.updateFiltered(filteredArray);
    }, 200);


    reset() {
        this.setState({filters: {search: "", email: "", gender: "", authorized: null, is_test: null, include_trial_golfers: null, is_dac: null, technology_provider: null, club_id: null, association_id: null}}, () => {
            this.updateTable();
        });
    }

    loadAssociations() {
        return ClubService.getGolfAssociations("global").then((data) => {
          var associations = data.associations
          if (this.state.filters.is_test.value === false) {
            associations = associations.filter((e) => e.is_test === false)
          }
            this.setState({
              associations: associations.map((item) => {
                    return {
                        label: item.name,
                        value: item.id
                    }
                }).sort((a, b) => a.label.localeCompare(b.label) )
            })
        })
    }

    loadClubs(association_id) {
        return AssociationService.getAllClubs(association_id, {}).then((data) => {
          this.setState({
            clubs: data.clubs.map((item) => {
                  return {
                      label: item.club_name,
                      value: item.id
                  }
              }).sort((a, b) => a.label.localeCompare(b.label) )
          })
        });
    }

    render() {
        return (
            <Fragment>
                <div className="filters columns">
                    <div className="row">
                        <div className={"filters__inputs col"}>
                            <div className={"row"}>
								<div className='filters__inputs col'>
									<div className={'row'}>
										<div className="col is-1-of-7">
											<label htmlFor="association-name">Golfer Name</label>
											<input type="text" autoComplete="off" value={this.state.filters.search} name="golfer-name" id="golfer-name" onChange={(e) => {
												this.updateFilter('search', e.target.value)
											}}/>
										</div>

										<div className="col is-1-of-6">
											<GhinFilterSelect
												options={['Active', 'Inactive', 'Archived']}
												value={this.state.filters.status}
												onChange={values => this.updateFilter('status', values)}
												label={'Membership Status'}
											/>
										</div>

										<div className="col is-1-of-6">
											<GhinFilterSelect
												singleChoice={true}
												options={[{value:'M', label: 'Male'}, {value:'F', label: 'Female'}]}
												value={this.state.filters.gender}
												onChange={values => this.updateFilter('gender', values)}
												label={'Gender'}
											/>
										</div>

										<div className={'col is-1-of-6'}>
											<GhinFilterSelect
												isSearchable={true}
												singleChoice={true}
												options={this.state.associations}
												value={this.state.filters.association_id}
												onChange={values => this.updateFilter('association_id', values)}
												label={'Association'}
											/>
										</div>


										<div className={'col is-1-of-6'}>
											<GhinFilterSelect
												isSearchable={true}
												singleChoice={true}
												options={this.state.clubs}
												value={this.state.filters.club_id}
												onChange={values => this.updateFilter('club_id', values)}
												label={'Club'}
											/>
										</div>

										<div className="col is-1-of-6">
											<label htmlFor="association-name">Email</label>
											<input type="text" autoComplete="off" value={this.state.filters.email} name="email-name" id="email-name" onChange={(e) => {
												this.updateFilter('email', e.target.value)
											}}/>
										</div>
									</div>
                  <div className={'row'}>
                    <div className="col is-1-of-7">
                      <GhinFilterSelect
                        options={yesNoOptions}
                        singleChoice={true}
                        value={this.state.filters.is_test}
                        onChange={values => this.updateFilter('is_test', values)}
                        label={'Is Test Golfer ?'}
                      />
                    </div>
                    <div className="col is-1-of-7">
                      <GhinFilterSelect
                        options={yesNoOptions}
                        singleChoice={true}
                        value={this.state.filters.include_trial_golfers}
                        onChange={values => this.updateFilter('include_trial_golfers', values)}
                        label={'Is Trial Golfer ?'}
                      />
                    </div>
                  </div>
								</div>
								<div className="filters__controls col is-1-of-7 jc-fe">
                                    <button className="btn fill gray" onClick={this.reset.bind(this)}>Reset</button>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </Fragment>
        );
    }
}
