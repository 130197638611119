import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AssociationService from "../../../../services/api/association";
import GhinTable from "../../../shared/GhinTable";
import ReactTooltip from "react-tooltip";
import ExportToHubspotModal from "../../../Modals/ExportToHubspotModal";
import InvalidHubspotSetupModal from "../../../Modals/InvalidHubspotSetupModal";
import StopHubspotConnectionModal from "../../../Modals/StopHubspotConnectionModal";
import ObjectMappingDetails from "./ObjectMappingDetails";
import { defaultlDataHubSpotTable, hubspotScopes, baseHubspostInstallUrl, getRedirectUrl, syncByValuesNames } from "./HubspotConstants"

class HubSpot extends Component {
  constructor(props) {
    super(props);

    if (process.env.REACT_APP_TYPE === 'pilot' && ![23, 98, 160, 20, 101, 81, 84, 18, 55, 10, 2, 73, 5, 19, 79, 1, 22, 57, 30, 65, 52, 104, 103].includes(props.associationId)) {
      this.defatulDataHubSpotTable = defaultlDataHubSpotTable.filter( obj => obj.id === 'golfers')
    }
    else {
      this.defatulDataHubSpotTable = defaultlDataHubSpotTable;
    }

    this.state = {
      settingsLoading: false,
      connectionLoading: false,
      mappingsLoading: false,
      invalidEnabledHubspotModalIsOpen: false,
      exportToHubspotModalIsOpen: false,
      stopHubspotConnectionModalIsOpen: false,
      hubspotEnabled: false,
      hubspotAuthentificated: false,
      clientId: '',
      connectionStatus: null,
      dataHubSpotTable: this.defatulDataHubSpotTable,
      hubspotObjectsMappings: { golfers: [], clubs: [], golfer_club_affiliations: [], guardian_minors: [] },
    };

    this.updateHubspotObjectsMappings = this.updateHubspotObjectsMappings.bind(this);
  }

  componentDidMount() {
    this.setState({ settingsLoading: true });
    this.getHubspotSetup()
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getHubspotStatus() {
    AssociationService.getHubspotSettings(this.props.associationId)
      .then(response => {
        this.setState({
          connectionStatus: response.hubspot_settings.connection_status,
        })
        if (response.hubspot_settings.connection_status !== 'in_progress')
          clearInterval(this.interval);
      })
      .catch(err => {
        console.error(err);
      })
  }

  getHubspotSetup() {
    AssociationService.getHubspotSettings(this.props.associationId)
      .then(response => {
        const hs = response.hubspot_settings
        if (hs.hubspot_enabled_data) {
          const newDataHubSpotTable = this.state.dataHubSpotTable.map(obj => {
            return {
              ...obj,
              syncBy: hs.hubspot_enabled_data[obj.id]?.sync_by,
              enable: hs.hubspot_enabled_data[obj.id]?.enabled || false,
              statuses: hs.hubspot_enabled_data[obj.id]?.statuses || defaultlDataHubSpotTable.find(e => e.id === obj.id)?.statuses,
              disabled: ((obj.id === 'golfer_club_affiliations' || obj.id === 'guardian_minors') && !hs.hubspot_enabled_data?.golfers?.enabled)
            }
          })
          this.setState({ dataHubSpotTable: newDataHubSpotTable })
        }
        if (hs.connection_status === 'in_progress') {
          clearInterval(this.interval);
          this.interval = setInterval(
            () => {
              this.getHubspotStatus();
            }, 5000
          );
        }
        this.setState({
          settingsLoading: false,
          hubspotEnabled: hs.hubspot_enabled,
          connectionStatus: hs.connection_status,
          clientId: hs.hubspot_client_id,
          hubspotAuthentificated: hs.hubspot_authentificated,
        })
        if (hs.hubspot_enabled && hs.hubspot_authentificated)
          this.getHubspotObjectMappings()
      })
      .catch(err => {
        console.error(err);
      })
  }

  enabledHubspotIntegration() {
    AssociationService.enableHubspotIntegration(this.props.associationId)
      .then(response => {
        window.location.href = `${baseHubspostInstallUrl}?client_id=${this.state.clientId}` +
          `&redirect_uri=${getRedirectUrl()}&scope=${hubspotScopes}`
      })
      .catch(err => {
        console.error(err);
      })
  }

  onStartInitialSync() {
    this.setState({ connectionLoading: true, exportToHubspotModalIsOpen: false });
    AssociationService.exportToHubspot(this.props.associationId)
      .then(response => {
        this.setState({
          connectionLoading: false,
          connectionStatus: response.connection_status
        })
        if (this.state.connectionStatus === 'in_progress') {
          clearInterval(this.interval);
          this.interval = setInterval(
            () => {
              this.getHubspotStatus();
            }, 5000
          );
        }
      })
      .catch(err => {
        console.error(err);
      })
  }

  getHubspotObjectMappings() {
    this.setState({ mappingsLoading: true });
    AssociationService.getHubspotMappings(this.props.associationId)
      .then(response => {
        this.setState({
          mappingsLoading: false,
          hubspotObjectsMappings: response.field_mappings
        })
      })
      .catch(err => {
        console.error(err);
      })
  }

  updateHubspotObjectsMappings(objectName, objectMapping) {
    this.setState({
      mappingDetailsLoading: false,
      hubspotObjectsMappings: {
        ...this.state.hubspotObjectsMappings,
        [objectName.toLowerCase()]: objectMapping,
      }
    })
  }

  updateHubspotEnabledObject(row) {
    const newDataHubSpotTable = this.state.dataHubSpotTable
      .map(e => e.id === row.id
        ? { ...e, enable: !row.enable }
        : e)
    if (row.id === 'golfers') {
      let objIndex = newDataHubSpotTable.findIndex((obj => obj.id == 'golfer_club_affiliations'))
      newDataHubSpotTable[objIndex].disabled = row.enable
      newDataHubSpotTable[objIndex].enable = false

      objIndex = newDataHubSpotTable.findIndex((obj => obj.id == 'guardian_minors'))
      newDataHubSpotTable[objIndex].disabled = row.enable
      newDataHubSpotTable[objIndex].enable = false
    }

    this.updateHubspotEnabledData(newDataHubSpotTable)
  }

  updateHubspotGolfersSyncBy(row, syncByValue) {
    const newDataHubSpotTable = this.state.dataHubSpotTable
      .map(e => e.id === row.id
        ? { ...e, syncBy: syncByValue }
        : e)

    this.updateHubspotEnabledData(newDataHubSpotTable)
  }

  updateHubspotEnabledStatus(row, status_name, status_enabled) {
    const newDataHubSpotTable = this.state.dataHubSpotTable
      .map(e => (e.id === row.id || (e.id === 'golfer_club_affiliations' && row.id === 'golfers'))
        ? { ...e, statuses: { ...row.statuses, [status_name]: status_enabled } }
        : e)

    this.updateHubspotEnabledData(newDataHubSpotTable)
  }

  updateHubspotEnabledData(newDataHubSpotTable) {
    const oldDataHubSpotTable = this.state.dataHubSpotTable;
    this.setState({ dataHubSpotTable: newDataHubSpotTable })
    const hubspot_enabled_data = {}
    newDataHubSpotTable.forEach(e =>
      hubspot_enabled_data[e.id] = {
        enabled: e.enable,
        statuses: e.statuses,
        sync_by: e?.syncBy
      })

    AssociationService.updateHubspotEnabledData(this.props.associationId, { hubspot_enabled_data: hubspot_enabled_data })
      .then((response) => {
        this.setState({ connectionStatus: response.connection_status })
      })
      .catch(err => {
        this.setState({ dataHubSpotTable: oldDataHubSpotTable })
        console.error(err);
      })
  }

  disableHubspotConnection() {
    this.updateHubspotEnabledData(this.state.dataHubSpotTable)
    this.setState({ stopHubspotConnectionModalIsOpen: false })
  }

  render() {
    const columnsHubSpotTable = [
      {
        Header: '#',
        accessor: 'id',
        show: false
      },
      {
        Header: '#',
        accessor: 'syncBy',
        show: false
      },
      {
        Header: '#',
        accessor: 'disabled',
        show: false
      },
      {
        Header: 'Enable',
        accessor: 'enable',
        Cell: props =>
          <span className='col_edit-delete'
            data-tip
            data-for={`enable-tooltip-${props.row['id']}`}>
            <input type="checkbox"
              disabled={props.row['disabled'] === true
                || this.state.connectionStatus === 'in_progress'
                || this.state.connectionStatus === 'exported'
              }
              checked={props.row.enable}
              onChange={() => this.updateHubspotEnabledObject(props.row)}
              name={props.row['id']}
              id={`enable-${props.row['id']}`} />
            <label htmlFor={`enable-${props.row['id']}`}></label>
            {
              props.row['disabled'] &&
              <ReactTooltip id={`enable-tooltip-${props.row['id']}`} place="bottom" type="dark" effect="solid">
                To export the {props.row['id']} object, the golfers object also needs to be enabled.
              </ReactTooltip>
            }
          </span>,
        thClassName: 'is-1-of-11 table-align-center',
        sortable: false,
      },
      {
        Header: 'Filters',
        accessor: 'statuses',
        thClassName: 'is-1-of-8',
        Cell: props =>
          <span style={{ width: "100%", flexFlow: 'column' }}>
            {
              props.row?.statuses &&
              Object.entries(props.row?.statuses).map(([status, enabled]) => (
                <React.Fragment key={`enable-${props.row.id}-${status}`}>

                  <input type="checkbox"
                    disabled={props.row.enable === false
                      || this.state.connectionStatus === 'in_progress'
                      || this.state.connectionStatus === 'exported'
                      || props.row?.id === 'golfer_club_affiliations'
                    }
                    checked={enabled}
                    onChange={() => this.updateHubspotEnabledStatus(props.row, status, !enabled)}
                    id={`enable-${props.row.id}-${status}`}
                  />
                  <label htmlFor={`enable-${props.row.id}-${status}`}>{status.charAt(0).toUpperCase() + status.slice(1)}</label>
                </React.Fragment>
              ))
            }
          </span>
      },
      {
        Header: 'GHIN',
        accessor: 'ghin',
        thClassName: 'is-3-of-8',
        sortable: false,
        Cell: props =>
          <div style={{ width: "100%" }}>
            {props.row.ghin}
            {
              props.row.id == 'golfers' && props.row?.syncBy &&
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ alignItems: 'center' }}>
                  Sync by
                  <i data-tip
                    data-for={'tooltip-sync-by'}
                    className="material-icons-outlined v-mid m-left dark-blue small"
                    style={{ marginLeft: '3px' }}>
                    info
                  </i>:
                </span>
                {
                  this.state.connectionStatus === 'in_progress' || this.state.connectionStatus === 'exported'
                    ? <span style={{ marginLeft: '10px' }}> {syncByValuesNames[props.row?.syncBy]} </span>
                    : Object.entries(syncByValuesNames).map(([value, name]) => (
                      <span key={`sync-by-${value}`} style={{ marginLeft: '20px', marginRight: '10px' }}>
                        <input type="checkbox"
                          disabled={props.row.enable === false}
                          checked={value == props.row?.syncBy}
                          onChange={() => this.updateHubspotGolfersSyncBy(props.row, value)}
                          id={`sync-by-${value}`}
                        />
                        <label htmlFor={`sync-by-${value}`} style={{ lineHeight: '20px' }}>{name}</label>
                      </span>
                    ))
                }
                <ReactTooltip id={'tooltip-sync-by'} place="bottom" type="dark" effect="solid">
                  Golfers without email are not exported if golfers are sync by email
                </ReactTooltip>
              </div>
            }
          </div>
      },
      {
        Header: 'Hubspot',
        accessor: 'hubspot',
        thClassName: 'is-2-of-8',
        sortable: false,
      },
      {
        Header: '',
        accessor: 'buttons',
        thClassName: 'is-1-of-8',
        expander: true,
        Expander: props =>
          <span style={{ width: "100%" }}>
            <button
              className="btn fill green"
            >
              View Details
            </button>
          </span>,
      }
    ];
    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <div className="panel">
              <div className="panel__head">
                HubSpot Setup
              </div>
              <div className="panel__body">
                <div className="row">
                  <div className="col jc-c">
                    <div className="content__container">
                      <div className="content">
                        <p>Connect to HubSpot and sync your GHIN data to HubSpot contacts and companies in real-time (confirm timing).</p>
                        <p>To connect you must have a Hubspot account with access to the API key. Custom objects will only sync for Enterprise Hubspot user</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col jc-fs">
                    <div className="data-set__container left">
                      <div className="data-set col ai-c">
                        <span className="data-label">
                          <span
                            // link color: rgb(0, 0, 238)
                            // visited link color rgb(85, 26, 139)
                            onClick={() => this.enabledHubspotIntegration()}
                            style={{ color: 'rgb(0, 0, 238)', textDecoration: 'underline', cursor: "pointer" }}
                          >
                            Enable Hubspot Integration
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col jc-fs">
                    <div className="data-set__container left">
                      <div className="data-set col ai-c">
                        <span className="data-label">Hubspot Enabled?</span>
                        {
                          !this.state.settingsLoading &&
                          <span>{this.state?.hubspotEnabled ? "Yes" : "No"}</span>
                        }
                      </div>
                      {
                        this.state.settingsLoading &&
                        <div
                          style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}
                        >
                          <span
                            style={{ position: 'absolute' }}
                            className="data-table__loader"></span>
                        </div>
                      }
                      {/* 
                      <div style={this.state.hubspotEnabled ? {} : { visibility: "hidden" }}>
                        <div className="data-set col ai-c">
                          <span className="data-label">Client Id: </span>
                          <span>{this.state?.clientId}</span>
                        </div>
                      </div>
                      */}
                    </div>
                  </div>
                </div>
                {this.state.hubspotEnabled && this.state?.hubspotAuthentificated &&
                  <>
                    <div className="separator dark"></div>
                    <div className="row">
                      <div className="col">
                        <h4 className="section_title">Objects</h4>
                      </div>
                      {this.state.connectionStatus === 'exported' ?
                        <div className="col auto push-right jc-c">
                          <button
                            className="btn fill red"
                            onClick={() => this.setState({ stopHubspotConnectionModalIsOpen: true })}
                          >
                            Stop Sync
                          </button>
                        </div> : null
                      }
                      <div className="col auto push-right jc-c">
                        {this.state.connectionStatus === 'exported' ?
                          <button
                            className="btn fill blue"
                            disabled
                            style={{ width: '135px' }}
                          >
                            Connected
                          </button>
                          : this.state.connectionStatus === 'in_progress' ?
                            <button
                              className="btn fill blue"
                              disabled
                              style={{ width: '135px' }}
                            >
                              Connecting
                            </button>
                            :
                            <button
                              className="btn fill blue"
                              disabled={this.state.dataHubSpotTable.every(e => !e.enable)
                                || this.state.dataHubSpotTable.some(e => e.enable && Object.values(e.statuses).every(status => !status))
                                || this.state.connectionLoading
                              }
                              onClick={() => this.setState({ exportToHubspotModalIsOpen: true })}
                              style={{ width: '135px' }}
                            >
                              Start Initial Sync
                            </button>
                        }
                      </div>
                      <div className="col auto-size push-right jc-c" style={{ flexGrow: 0 }}>
                        <span className="flex jc-c" style={{ display: 'flex', alignItems: 'center', border: "solid 1px #cccccc", padding: '5px' }}>
                          <i className="material-icons-outlined blue" style={{ marginRight: '10px' }}>
                            arrow_circle_right
                          </i>
                          Data flows from GHIN to HubSpot Only
                        </span>
                      </div>
                    </div>
                    {this.state.mappingsLoading ?
                      <table style={{ width: '100%' }}>
                        <tbody >
                          <tr>
                            <td style={{ textAlign: 'center', padding: '50px' }}>
                              <span className="data-table__loader"></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      :
                      <div className="row">
                        <div className="col">
                          <GhinTable
                            columns={columnsHubSpotTable}
                            data={this.state.dataHubSpotTable}
                            total={this.state.dataHubSpotTable.length}
                            hideSelect={true}
                            hideSelectAll={true}
                            hidePagination={true}
                            SubComponent={rowInfo =>
                              <ObjectMappingDetails
                                rowInfo={rowInfo}
                                editable={this.state.connectionStatus !== 'in_progress' && this.state.connectionStatus !== 'exported'}
                                associationId={this.props.associationId}
                                objectMapping={this.state.hubspotObjectsMappings[rowInfo.original.id]}
                                updateHubspotObjectsMappings={this.updateHubspotObjectsMappings}
                              />}
                          />
                        </div>
                      </div>
                    }

                  </>
                }
              </div>
            </div>
          </div>
        </div>
        {this.state.invalidEnabledHubspotModalIsOpen &&
          <InvalidHubspotSetupModal
            closeModal={() => this.setState({ invalidEnabledHubspotModalIsOpen: false })}
          />
        }
        {this.state.stopHubspotConnectionModalIsOpen &&
          <StopHubspotConnectionModal
            closeModal={() => this.setState({ stopHubspotConnectionModalIsOpen: false })}
            onSubmit={() => this.disableHubspotConnection()}
          />
        }
        {this.state.exportToHubspotModalIsOpen &&
          <ExportToHubspotModal
            closeModal={() => this.setState({ exportToHubspotModalIsOpen: false })}
            onSubmit={() => this.onStartInitialSync()}
          />
        }
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  let actions = bindActionCreators({}, dispatch);
  return {
    dispatch,
    ...actions,
  };
}

export default connect(mapDispatchToProps)(HubSpot);
